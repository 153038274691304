<template>
	<v-row>
		<v-col cols="6">
			<v-card>
				<v-card-title primary-title>
					{{ materi.tipe }}
				</v-card-title>
				<v-card-text>
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td style="width: 130px;"><b>Agenda</b></td>
									<td style="width: 10px;">:</td>
									<td>Hari ke {{ hari_ke }}, {{ formatDate(materi.tanggal) }}</td>
								</tr>
								<tr>
									<td><b>Nama</b></td>
									<td>:</td>
									<td>{{ materi.nama }}</td>
								</tr>
								<tr v-if="materi.tipe == 'EVALUASI NARASUMBER'">
									<td><b>Pemateri</b></td>
									<td>:</td>
									<td>{{ materi.nama_user }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="6">
			<v-card>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<h3>Daftar Pertanyaan</h3>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Pertanyaan</th>
									<th>Kurang | Sedang | Baik | Sangat Baik</th>

								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in table.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.pertanyaan }}</td>
									<td>
										<v-radio-group v-model="form.edit.jawabans[key].jawaban" row>
											<v-radio value="55" class="ml-2"></v-radio>
											<v-radio value="75" class="ml-1"></v-radio>
											<v-radio value="85" class="ml-1"></v-radio>
											<v-radio value="100"></v-radio>
										</v-radio-group>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="3">
										<v-textarea v-if="form.is_saved == 0" outlined dense label="Komentar dan Saran:"
											v-model="form.edit.saran"></v-textarea>
										<v-textarea v-else readonly outlined dense label="Komentar dan Saran:"
											v-model="form.edit.saran"></v-textarea>

									</td>
								</tr>
								<tr>
									<td colspan="2"></td>
									<td>
										<v-btn v-if="form.is_saved == 0" small class="my-1" color="primary"
											:loading="form.loading" @click="saveJawaban()">Simpan</v-btn>
									</td>
								</tr>
							</tfoot>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<style scoped>
table tr td {
	vertical-align: top;
}

.pdf {
	width: 100%;
	aspect-ratio: 4 / 3;
}

.pdf,
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose } from '@mdi/js'

export default {
	components: {
	},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose
			},
			id_pelatihan: this.$route.params.id_pelatihan,
			id_materi: this.$route.params.id_materi,
			hari_ke: this.$route.params.hari_ke,
			pelatihan: {},
			materi: {
				tanggal: '',
				nama: '',
				deskripsi: '',
				jenis_tugas: '',
				nama_user: '',
				file_url: '',
			},
			form: {
				is_saved: 0,
				edit: {
					jawabans: [],
					saran: '',
				},
				loading: false,
			},
			table: {
				items: []
			},
		}
	},
	watch: {
	},
	computed: {
	},
	created() {
		this.getPelatihan()
		this.getMateri()
		this.getPertanyaanSaved()
	},
	methods: {
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		getMateri() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getTugasPeserta', fmData)
				.then(response => {
					this.materi = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		saveJawaban() {
			this.form.loading = true
			const fmData = new FormData()
			fmData.append('jawabans', JSON.stringify(this.form.edit.jawabans))
			fmData.append('saran', this.form.edit.saran)
			fmData.append('id_materi', this.id_materi)
			axiosPostAuth('api/Evaluasi/saveEvaluasiPemateriJawaban', fmData)
				.then(response => {
					if (response.code === 200) {
						this.$notify({
							type: 'success',
							title: 'Berhasil!',
							text: response.message,
						});
						this.getPertanyaanSaved()
					}
					else {
						alert(response.message)
					}
					this.form.loading = false
				})
				.catch(error => {
					alert(error)
				})
		},
		getPertanyaanSaved() {
			const fmData = new FormData()
			fmData.append('id_materi', this.id_materi)
			axiosPostAuth('api/Evaluasi/getEvaluasiPemateriJawaban', fmData)
				.then(response => {
					this.table.items = response.data
					this.form.is_saved = response.is_saved
					if (this.form.is_saved == 0) {
						this.form.edit.saran = ''
					} else {
						this.form.edit.saran = response.saran.saran
					}
					response.data.forEach((element, key) => {
						// console.log(element,key);
						this.form.edit.jawabans.push({
							idPertanyaan: element.id,
							jawaban: response.is_saved == 0 ? 0 : element.nilai
						})
					});
				})
				.catch(error => {
					alert(error)
				})
		},
	}
}
</script>